import styled, { css } from "styled-components";
import { FixedBarContainer } from "../common/styles/Containers";
import palette from "../../lib/styles/palette";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify"; // React Router의 useNavigate import

export type BottomActionBarProps = {
    buttonAvailable: boolean;
    totalPaymentAmount: number;
    programId?: number; // 프로그램 ID
    count?:number;
};



function BottomActionBar(props: BottomActionBarProps) {
    const { buttonAvailable, totalPaymentAmount, programId, count } = props;
    const navigate = useNavigate(); // useNavigate 훅 사용

    const handlePaymentClick = async () => {
        if (!buttonAvailable) return;

        if (!programId) {
            alert("프로그램 정보를 찾을 수 없습니다.");
            return;
        }

        const token = localStorage.getItem('login-token');
        if (!token) {
            toast.error("로그인이 필요합니다.");
            navigate("/login");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/payment/book`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    program: {
                        id: programId, // ProgramDto 내부의 id
                    },
                    amount: totalPaymentAmount, // totalPaymentAmount를 amount로 매핑
                    count : count,
                }),
            });

            if (response.status === 200) {
                const result = await response.json();
                alert("결제 예약이 성공적으로 완료되었습니다!");
                console.log(result);
                // 결제가 성공하면 결제 상세 페이지로 이동
                navigate(`/payment-detail/${result.id}`);
            } else {
                alert("결제 예약에 실패했습니다. 다시 시도해 주세요.");
            }
        } catch (error) {
            console.error("결제 요청 실패:", error);
            alert("서버와의 연결이 원활하지 않습니다.");
        }
    };

    return (
        <FixedBarContainer locate="bottom">
            <Block>
                <SubmitBtn disabled={!buttonAvailable} onClick={handlePaymentClick}>
                    {totalPaymentAmount.toLocaleString()}원 결제하기
                </SubmitBtn>
            </Block>
        </FixedBarContainer>
    );
}

const Block = styled.div`
    width: 100%;
    background-color: ${palette.white0};
    padding: 10px 20px;
`;

const SubmitBtn = styled.button`
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: ${palette.white0};
    background-color: ${palette.red500};
    border-radius: 5px;
    padding: 12px 0;

    ${(props) =>
        props.disabled &&
        css`
            background-color: ${palette.gray4};
        `}
`;

export default BottomActionBar;
