import styled from "styled-components";
import PageTemplate from "../../components/basics/PageTemplate";
import ProductInfo from "../../components/common/ProductInfo";
import { getSampleImage } from "../../lib/styles/utils";
import PageHeader from "../../components/common/PageHeader";
import { SectionDivider } from "../../components/common/styles/Common";
import PaymentInfoSection from "../../components/mypage/paymentDetail/PaymentInfoSection";
import { DefaultButton } from "../../components/common/styles/Buttons";
import palette from "../../lib/styles/palette";
import OptionInfo from "../../components/common/OptionInfo";
import {useEffect, useState} from "react";



function PaymentDetail() {
    const [payment, setPayment] = useState([{
        id: "",
        program: {
            regDate: "",
            id: 17,
            thumbnail: "",
            title: "",
            region: "",
            rating: 4.8,
            reviewCnt: 0,
        },
        specific: "",
        amount: 30000,
        username: "minglemood@gmail.com",
        status: "R",
        count : 1,
    }]);
    const logintoken = localStorage.getItem('login-token');
    const handlePaymentDetail = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        const response = await fetch(
            process.env.REACT_APP_HOST + "/payment/reserve",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${logintoken}`
                },
            }
        );

        if (response.status === 200) {
            const result = await response.json();
            setPayment(result); // 유저 정보 상태에 저장
        } else {
            alert("알수 없는 오류, 고객센터에 문의 주시기 바랍니다.");
        }

    };

    useEffect(() => {
        handlePaymentDetail(); // 컴포넌트가 마운트될 때 유저 정보 불러오기
    }, []);

    return (
        <PageTemplate>
            <PageHeader title="예약 상세" />
            <ProductInfoSection>
            <ProductInfo
                thumbnail={payment[0]?.program.thumbnail}
                region={payment[0]?.program.region}
                title={payment[0]?.program.title}
                rating={payment[0]?.program.rating || 0}
                reviewCnt={payment[0]?.program.reviewCnt || 0}
            />

                <OptionInfo
                    title="옵션정보"
                    optionList={[
                        {
                            label: "일정",
                            value: payment[0]?.specific || '',
                        },
                        {
                            label: "인원",
                            value: `${payment[0]?.count || 0}인`,
                        },
                        {
                            label: "가격",
                            value: `${(payment[0]?.amount || 0).toLocaleString()}원`,
                        },
                    ]}
                />
            </ProductInfoSection>
            <SectionDivider />
            <PaymentInfoSection
                paymentAmount={payment[0]?.amount || 0}
                paymentMethod={
                    payment[0]?.status === "R"
                        ? "입금대기"
                        : payment[0]?.status === "P"
                            ? "예약중"
                            : ""
                }
            />
            {/* 계좌번호 표시 로직 추가 */}
            {payment[0]?.status === "R" && (
                <AccountInfo>
                    <p>입금 계좌 정보:</p>
                    <p>
                        <strong>102-128967-04-013</strong> - 기업은행 밍글무드
                    </p>
                </AccountInfo>
            )}
            <BtnWrapper>
                <CancelBtn styleType="filled" color={palette.red500}>
                    예약 취소
                </CancelBtn>
            </BtnWrapper>
        </PageTemplate>
    );
}

const AccountInfo = styled.div`
    margin: 20px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid ${palette.red500}; /* 1px 테두리 추가 */
    color: ${palette.black0};
    font-size: 13px;
    line-height: 1.5;

    & p {
        margin: 0;
        &:first-child {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
`;

const ProductInfoSection = styled.section`
    display: grid;
    row-gap: 20px;
    padding: 20px 0 30px;
    margin-top: 50px;
`;

const BtnWrapper = styled.div`
    margin-top: 80px;
    padding: 0 20px;
`;

const CancelBtn = styled(DefaultButton)`
    width: 100%;
    height: 40px;
    font-size: 13px;
`;

export default PaymentDetail;
