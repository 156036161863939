import styled from "styled-components";
import AvatarSection from "../components/setting/AvatarSection";
import palette from "../lib/styles/palette";
import AliasSection from "../components/setting/AliasSection";
import BioSection from "../components/setting/BioSection";
import PageTemplate from "../components/basics/PageTemplate";
import PageHeader from "../components/common/PageHeader";
import { getSampleUser } from "../lib/data/sampleUserData";
import {ChangeEvent, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// User 인터페이스 정의
interface UserInfo {
    id: number;
    nickname: string;
    bio: string;
    avatar: string;
}

function Setting() {
    const user = getSampleUser();
    const [avatar, setAvatar] = useState<string>(user.avatar);
    const [selectedImage, setSelectedImage] = useState<File | null>(null); // 업로드된 이미지 파일
    const [userInfo, setUserInfo] = useState<UserInfo>({
        id: 0,
        nickname: user.alias,
        bio: user.bio,
        avatar:  process.env.REACT_APP_HOST + "/" + user.avatar,
    });
    const navigate = useNavigate();

    const onChangeAvatar = (file: File, previewUrl: string) => {
        setSelectedImage(file);
        setAvatar(previewUrl);
    };

    const onChangeAlias = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUserInfo((prevState) => ({
            ...prevState,
            nickname: value,
        }));
    };

    const handleChangeBio = (value: string) => {
        setUserInfo((prevState) => ({
            ...prevState,
            bio: value,
        }));
    };

    const onClickSaveBtn = async () => {
        const token = localStorage.getItem("login-token");
        if (!token) {
            toast.error("로그인이 필요합니다.");
            navigate("/login");
            return;
        }

        const formData = new FormData();
        formData.append("id", userInfo.id.toString());
        formData.append("nickname", userInfo.nickname);
        formData.append("bio", userInfo.bio);
        if (selectedImage) {
            formData.append("avatar", selectedImage);
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_HOST + "/members/update",
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    body: formData,
                }
            );

            if (response.ok) {
                toast.success("프로필 수정을 완료했습니다.");
                navigate("/mypage");
            } else {
                const errorText = await response.text();
                toast.error(`프로필 수정에 실패했습니다. ${errorText}`);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("서버와의 통신에 실패했습니다.");
        }
    };

    useEffect(() => {
        let isMounted = true;

        const handleSetting = async () => {
            const logintoken = localStorage.getItem("login-token");
            if (logintoken) {
                const response = await fetch(
                    process.env.REACT_APP_HOST + "/members/mypage",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${logintoken}`,
                        },
                    }
                );

                if (response.status === 200 && isMounted) {
                    const result = await response.json();
                    setUserInfo(result);
                    setAvatar(process.env.REACT_APP_HOST + "/" + result.avatar);
                } else if (!response.ok && isMounted) {
                    alert("로그인이 필요한 페이지 입니다.");
                    navigate("/login");
                }
            } else if (isMounted) {
                alert("로그인이 필요한 페이지 입니다.");
                navigate("/login");
            }
        };

        handleSetting();

        return () => {
            isMounted = false;
        };
    }, [navigate]);

    return (
        <PageTemplate>
            <PageHeader
                title="프로필 수정"
                rightSlot={
                    <SaveButton onClick={onClickSaveBtn}>저장</SaveButton>
                }
            />
            <AvatarSection avatar={avatar} onChange={onChangeAvatar} />
            <AliasSection nickname={userInfo?.nickname || ""} onChange={onChangeAlias} />
            <BioSection bio={userInfo?.bio || ""} handleChange={handleChangeBio} />
        </PageTemplate>
    );
}

const SaveButton = styled.button`
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: ${palette.red500};
    margin-left: auto;
`;

export default Setting;